<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考评员管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.fullname" type="text" size="small" placeholder="姓名" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="to_edit">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="账号" align="left" show-overflow-tooltip prop="adminName" minWidth="100" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="fullname" minWidth="80" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" minWidth="120" />
              <el-table-column label="联系方式" align="left" show-overflow-tooltip prop="mobile" minWidth="100" />
              <el-table-column label="作业类别" align="left" show-overflow-tooltip prop="workCategory" minWidth="100">
                <template slot-scope="scope">
                  {{ scope.row.workCategory ? $setDictionary("PA_WORK_CATEGORY", scope.row.workCategory) : "" }}
                </template>
              </el-table-column>
              <el-table-column label="是否启用" align="left" show-overflow-tooltip prop="state" minWidth="80">
                <template slot-scope="scope">
                  <el-switch :width="20" v-model="scope.row.state" active-value="10" inactive-value="20"
                    active-color="#13ce66" @change="
                      (val) => {
                        handleStu(val, scope.row.adminId);
                      }
                    "></el-switch>
                  <span>{{ scope.row.state == 10 ? "启用" : "禁用" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" minWidth="140" />
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="handlePassword(scope.row)">重置密码</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_edit(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPasswordData.dialogVisible" width="30%" center>
      <div class="flexdcc">
        <p>是否将用户密码重置？</p>
        <p>重置后，变为初始密码Aa123456</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordData.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doPassword" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "systemManage_examinerManage",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullname: "", // 姓名
      },
      // 重置密码 - 弹框数据
      resetPasswordData: {
        dialogVisible: false, // 弹框状态
        adminId: "", // 用户id
      },
    };
  },
  created() {

  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/biz/affiliation/pa-examiner-affiliation/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 修改启用状态
    handleStu(state, adminId) {
      this.$post("/sys/admin/modifyStat", {
        adminId,
        state,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(this.pageNum);
        }
      });
    },
    // 重置密码 - 打开弹框
    handlePassword(row) {
      this.resetPasswordData.dialogVisible = true;
      this.resetPasswordData.adminId = row.adminId;
    },
    // 重置密码 - 打开弹框 - 确定
    doPassword() {
      this.$post("/sys/admin/restPassword", { adminId: this.resetPasswordData.adminId }).then(
        (res) => {
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: "密码重置成功",
            });
            this.resetPasswordData = this.$options.data().resetPasswordData;
            this.getData(this.pageNum);
          }
        }
      );
    },
    // 编辑
    to_edit(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/systemManage/examinerManage_addAndedit",
        query: {
          adminId: row.adminId
        }
      })
    },
  },
};
</script>
<style lang="less" scoped></style>